import { Injectable } from '@angular/core';

@Injectable()
export class AuthProvider {

  constructor(){

  }


  hasToken(){
    return !!localStorage.getItem('JWT');
  }

  hasUser(){
  	return !!localStorage.getItem('user');
  }

  setToken(token){
  	localStorage.setItem('JWT',token);
    console.log('Token set: ' + token);
  }

  setUser(user){
  	localStorage.setItem('user',JSON.stringify(user));
  }

  getToken(){
    return localStorage.getItem('JWT');
  }

  getUser(){
  	return JSON.parse(localStorage.getItem('user'));
  }

}