import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { OrderProvider } from '../../providers/order.provider';
import {UserNoteProvider} from '../../providers/user-note.provider';
declare var google;
@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss'],
  providers: [
    OrderProvider,
    UserNoteProvider
  ]
})
export class OrderDetailComponent implements OnInit {

  public editingNoteComment = false;
  public id: any;
  public type: any;
  public pool: any;
  public order: any = {
    company: {},
    customer: {},
    deliveryAddress: {},
    tank: {},
  };
  public backLink = '/orders';
  public deliveryStatus = null;
  public deliveryQuantity = null;
  public deliveryStatusOptions = ['delivered', 'rejected'];
  constructor (
    private route: ActivatedRoute,
    private orderProvider: OrderProvider,
    private userNoteProvider: UserNoteProvider,
    public toastr: ToastrService
  ) {
    this.route.data.subscribe((data: any) => {
      this.type = data.type;
    });
    this.route.queryParams.subscribe((data: any) => {
      if (data.back) {
        this.backLink = data.back;
      }
    });
    this.getId();
  }

  ngOnInit() {

  }

  getId() {

    this.route.params.subscribe((params: any) => {
      this.id = params.id;
    });

    if (this.type === 'spot') {
      this.getOrder();
    }
    if (this.type === 'pool') {
      this.getPoolOrder();
    }
  }

  getPoolOrder() {
    this.orderProvider
    .getPoolOrder(this.id)
    .then((data: any) => {
      this.pool = data;
      this.order = data;
      this.loadMap();
    })
    .catch(err => {
      console.log(err);
    })
  }
  getOrder() {

    return this.orderProvider
      .getOrder(this.id)
      .then((data: any) => {
        console.log(data);

        this.deliveryStatus = data.deliveryStatus;
        this.deliveryQuantity = Number(data.deliveredAmount);

        this.order = data;
        this.loadMap();
      })
      .catch(err => {
        // TODO: proper error handling
        console.error(err);
      });
  }

  getOrderTypeText(order) {
    const types = {
      pool: 'Pool',
      spot: 'Spot',
    };
    return types[order.priceType];
  }
  getOrderDeliveryStatusText(order) {
    const statuses = {
      pending: 'Awaiting approval',
      accepted: 'Accepted',
      rejected: 'Rejected',
      delivered: 'Delivered',
    };
    return statuses[order.deliveryStatus];
  }
  getOrderDeliveryStatusClass(order) {
    return order.deliveryStatus;
  }

  setOrderDeliveryStatus(id, status) {
    this.orderProvider.setOrderDeliveryStatus(id, status)
    .then(response => {
      this.toastr.success('Order status changed to "' + status + '"!', 'Success!');
      this.getId();
    })
  }

  setOrderPaymentStatus(id, status) {
    this.orderProvider.setPaymentStatus(id, status)
    .then(response => {
      this.toastr.success('Payment status changed to "' + status + '"!', 'Success!');
      this.getId();
    });
  }
  loadMap() {
    const myLatLng = {lat: 40.7128, lng: 74.0059};
    const that = this;
    setTimeout(function() {
      const map = new google.maps.Map(document.getElementById('map-canvas'), {
        zoom: 5,
        center : myLatLng
      });
      let orders = [];
      if (that.type == 'pool') {
        orders = that.pool.orders;
      } else {
        orders.push(that.order);
      }


      for (const key in orders) {
        const location = orders[key].deliveryAddress.googleMaps;
        console.log(location.location);
        map.setCenter(location.location);
        const marker = new google.maps.Marker({
          place: {
              placeId: location.placeId,
              location: location.location
          },
          map: map,
          title: orders[key].deliveryAddress.line1
        });
      }

    }, 0);
  }

  editNoteComment() {
    this.editingNoteComment = true;
    if (this.order.customerNote) {
      this.order._comment = this.order.customerNote.comment;
    }
  }

  saveNoteComment() {
    this.editingNoteComment = false;

    this.userNoteProvider.postUserNote(this.order.companyId, this.order.userId, this.order._comment).then(() => {
      this.order.customerNote.comment = this.order._comment;
    });
  }

  async updateStatus() {
    const response = await this.orderProvider.setOrderDeliveryStatus(this.id, this.deliveryStatus);
    if (response) {
      this.toastr.success(`Order status changed to ${this.deliveryStatus}!`, 'Success!');
      this.getId();
    }
  }

  async updateQuantity() {
    const response = await this.orderProvider.setOrderDeliveryStatus(this.id, null, this.deliveryQuantity);
    if (response) {
      this.toastr.success(`Order delivery quantity changed to ${this.deliveryQuantity}!`, 'Success!');
      this.getId();
    }
  }

}
