import { Component } from '@angular/core';

@Component({
  selector: 'app-autofill',
  templateUrl: './autofill.component.html',
  styleUrls: ['./autofill.component.scss', '../pricing/pricing.component.scss'],
  providers: []
})
export class AutofillComponent {
  constructor() { }
}
