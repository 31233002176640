import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

import { ToastrService } from 'ngx-toastr';

import { ConfigProvider } from '../../../providers/config.provider';

@Component({
  selector: 'pricing-config-fields',
  templateUrl: './config-fields.component.html',
  styleUrls: ['./config-fields.component.scss'],
  providers: [
    ConfigProvider,
  ],
})
export class PricingConfigFieldsComponent implements OnInit {
  // Component for displaying and modifying pricing config.
  // Can be used for both company and default values.

  constructor(
    private configProvider : ConfigProvider,
    private toastr : ToastrService,
  ) { }

  @Input() set companyId(companyId) {
    this._companyId$.next(companyId);
  }
  get companyId() {
    return this._companyId$.getValue();
  }

  // 'default' or 'company'
  @Input() type: string;

  @Input() set useDefault(useDefault) {
    this.useDefault$.next(useDefault);
  }
  get useDefault() {
    return this.useDefault$.getValue();
  }

  @Output() saved = new EventEmitter<boolean>();
  @Output() changed = new EventEmitter<boolean>();

  useDefault$ = new BehaviorSubject<boolean>(null);
  private _companyId$ = new BehaviorSubject<number>(undefined);
  private _loadTrigger$ = new Subject();
  private _saveTrigger$ = new Subject();

  // defaultValues is used to display defaults when company value is not set. Only used when type is 'company'
  defaultValues$ = new BehaviorSubject<any>(null);
  // values is used for displaying and changing current values for both company and default.
  values$ = new BehaviorSubject<any>(null);

  fields: any = ['todayAvgPrice', 'opisPrice', 'deliveryFeeBase', 'ccFee', 'poolCommission', 'donationPerGallon'];

  ngOnInit() {
    this._loadTrigger$
      .flatMap(() => {
        if (this.type === 'company') {
          return this._companyId$.filter(companyId => !!companyId);
        }
        return Observable.of(undefined);
      })
      .flatMap(companyId => {
        return Observable.forkJoin([
          // Default values
          this._loadValues(),
          // Company values or null
          companyId ? this._loadValues({companyId}) : Observable.of(undefined),
        ]);
      })
      .subscribe(data => {
        this.defaultValues$.next(data[0]);
        this.values$.next(data[1] || data[0]);
      });

    this._loadTrigger$.next();

    this._saveTrigger$
      .flatMap(() => {
        if (this.useDefault && this.companyId) {
          // Set all values to null. That deletes company values and default values will be in effect.
          Object.keys(this.values$.getValue()).forEach(name => {
            let value = this.values$.getValue()[name];
            value._newValue = null;
          });
        }
        return this._saveValues();
      })
      .subscribe((result) => {
        // If there was nothing to save then result is undefined and no need to reload the data.
        if (result) {
          this._loadTrigger$.next();
        }
        this.toastr.success('Changes saved!', 'Success!');
        this.saved.emit(true);
      });
  }

  public getLabel(name) {
    return this.values$.getValue()[name].text || this.defaultValues$.getValue()[name].text;
  }
  public getValue(name) {
    if (this.useDefault) {
      return this.defaultValues$.getValue()[name].value;
    }
    return this.values$.getValue()[name].value || this.defaultValues$.getValue()[name].value;
  }
  public getValueForPlaceholder(name) {
    if (!this.companyId) {
      return;
    }
    return (this.values$.getValue()[name]._newValue || this.defaultValues$.getValue()[name].value) + (this.isDefault(name) ? ' (default)' : '');
  }
  public isDefault(name) {
    return this.useDefault || !this.values$.getValue()[name] || [undefined, null, ''].indexOf(this.values$.getValue()[name]._newValue) !== -1;
  }

  // TODO: indicate when data is loading
  // TODO: error handling

  public save() {
    this._saveTrigger$.next();
  }

  public someUndefined() {
    return !this.companyId && Object.keys(this.values$.getValue()).some(name => !this.values$.getValue()[name] || [undefined, null, ''].indexOf(this.values$.getValue()[name]._newValue) !== -1);
  }

  public onValueChanged() {
    this.changed.emit(true);
  }

  private _saveValues() {
    let values = Object
      .keys(this.values$.getValue())
      .filter(name => {
        let value = this.values$.getValue()[name];
        let oldVal = value.value && value.value.toString();
        let newVal = value._newValue && value._newValue.toString();
        return oldVal !== newVal;
      })
      .map(name => ({name, value: this.values$.getValue()[name]._newValue}));

    if (!values.length) {
      return Observable.of(null);
    }

    return Observable.fromPromise(this.configProvider.updateBulk({values, companyId: this._companyId$.getValue()}));
  }

  private _loadValues({companyId = undefined} = {}) {
    return Observable
      .fromPromise(this.configProvider.getAll({companyId}))
      .map((valuesResponse: any) => {
        let values = {};

        // Add empty values. If they do not exist then they won't be in response list and would stay undefined
        this.fields.forEach(field => {
          values[field] = {name: field};
        });

        for (let value of valuesResponse.list) {
          if (!values[value.name]) {
            continue;
          }

          value._newValue = value.value;
          values[value.name] = value;
        }

        return values;
      });
  }
}
