import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

import { Decimal } from 'decimal.js';
import { ToastrService } from 'ngx-toastr';

import { ConfigProvider } from '../../../providers/config.provider';

@Component({
  selector: 'company-pricing',
  templateUrl: './company-pricing.component.html',
  styleUrls: ['./company-pricing.component.scss'],
  providers: [
    ConfigProvider,
  ],
})
export class CompanyPricingComponent implements OnInit {
  // Component for displaying and modifying company pricing.

  constructor(
    private _configProvider : ConfigProvider,
    private _toastr : ToastrService,
  ) { }

  @Input() set companyId(companyId) {
    this._companyId$.next(companyId);
  }
  get companyId() {
    return this._companyId$.getValue();
  }

  @Input() set useDefault(useDefault) {
    this.useDefault$.next(useDefault);
  }
  get useDefault() {
    return this.useDefault$.getValue();
  }

  @Output() saved = new EventEmitter<boolean>();
  @Output() changed = new EventEmitter<boolean>();
  @Output() useDefaultSet = new EventEmitter<boolean>();

  pricing$ = new BehaviorSubject<any>(undefined);
  // If company config is not set then use isDefault will be true.
  useDefault$ = new BehaviorSubject<boolean>(null);
  error = null;
  hasChanged$ = new BehaviorSubject<boolean>(false);

  private _companyId$ = new BehaviorSubject<number>(undefined);
  private _loadTrigger$ = new Subject<number>();
  private _saveTrigger$ = new Subject();

  ngOnInit() {
    // When load is triggered load pricing
    this._loadTrigger$
      .switchMap((companyId: number) => {
        return Observable
          .fromPromise(this._configProvider.getOne({name: 'companyPricing', companyId}, {disableToastr: true}))
          .catch((err) => {
            if (companyId && err.status === 404) {
              // companyId is set and config is not found. Load default.
              if (this.useDefault$.getValue() === null) {
                // useDefault flag is not set. Set it to true to indicate that defaults are used for this commpany.
                this.useDefault$.next(true);
              } else {
                // useDefault is already set. This can occur if user sets the flag to false, but companyPricing is not found,
                // because company uses defaults and no config for this company. Do not set flag back to true,
                // but just load default values to be used as company values.
                this._loadTrigger$.next(undefined);
              }
              return Observable.of(undefined);
            }
            this._toastr.error(err.msg, 'Whoops!');
            throw err;
          });
      })
      .subscribe((pricing: any) => this.pricing$.next(pricing && pricing.value));

    // Reload when companyId changes
    this._companyId$
      .distinctUntilChanged()
      .subscribe(companyId => this._loadTrigger$.next(companyId));

    this._saveTrigger$
      .do(() => this.error = null)
      .map(() => this.pricing$.getValue())
      .switchMap((pricing: any) => {
        if (this.useDefault$.getValue() && this.companyId) {
          // Using default for company. Delete company specific config.
          return Observable
            .fromPromise(this._configProvider.deleteOne({name: 'companyPricing', companyId: this.companyId}))
            .map(() => ({}));
        }

        return Observable
          .fromPromise(this._configProvider.update({name: 'companyPricing', value: pricing, companyId: this._companyId$.getValue()}))
          .catch((err) => {
            // HttpErrorResponse, then details (response body) are in error property.
            this.error = err.error || err;
            return Observable.of(null);
          });
      })
      .subscribe((responseBody: any) => {
        if (!responseBody) {
          // Error happened. Do nothing this is handled in the previous step.
          return;
        }
        this._toastr.success('Changes saved!', 'Success!');
        this.hasChanged$.next(false);
        if (responseBody.value) {
          this.pricing$.next(responseBody.value);
        }
        this.saved.emit(true);
      });

    this.useDefault$
      .distinctUntilChanged()
      .subscribe((useDefault) => {
        this.useDefaultSet.emit(useDefault);
        if (useDefault) {
          this._loadTrigger$.next(undefined);
        } else {
          this._loadTrigger$.next(this._companyId$.getValue());
        }
      });

    this.hasChanged$
      .subscribe((hasChanged) => {
        if (hasChanged) {
          this.changed.emit(true);
        }
      });
  }

  save() {
    this._saveTrigger$.next();
  }

  setUseDefault(useDefault) {
    this.hasChanged$.next(true);
    this.useDefault$.next(useDefault);
  }

  change() {
    this.hasChanged$.next(true);
  }

  /* Functions for manipulating and validating pricing */
  changeRange(index, field) {
    this.hasChanged$.next(true);

    let range = this.pricing$.getValue().ranges[index];

    if (field === 'min' && index > 0) {
      let prevRange = this.pricing$.getValue().ranges[index - 1];

      let thisMin = CompanyPricingComponent._parseDecimal(range.min);
      if (!thisMin) {
        return;
      }

      let prevMax = thisMin.minus('0.01');

      range.min = thisMin.toFixed(2);
      prevRange.max = prevMax.toFixed(2);
    }

    if (field === 'max') {
      let nextRange = this.pricing$.getValue().ranges[index + 1];
      if (!nextRange) {
        return;
      }

      let thisMax = CompanyPricingComponent._parseDecimal(range.max);
      if (!thisMax) {
        return;
      }

      let nextMin = thisMax.plus('0.01');

      range.max = thisMax.toFixed(2);
      nextRange.min = nextMin.toFixed(2);
    }
  }

  addRange() {
    this.hasChanged$.next(true);

    let ranges = this.pricing$.getValue().ranges;
    let lastRange = ranges[ranges.length - 1];
    ranges.push({
      min: '',
      max: '',
      pricePerGallon: lastRange.pricePerGallon,
      priceFixed: lastRange.priceFixed,
    });
  }

  removeRange(index) {
    this.hasChanged$.next(true);

    let ranges = this.pricing$.getValue().ranges;
    if (ranges.length <= 1) {
      // At least one range must be defined
      return;
    }
    ranges.splice(index, 1);
    this.fixRanges();
  }

  fixRanges() {
    let ranges = this.pricing$.getValue().ranges;
    ranges.forEach((range, index) => {
      if (index === 0) {
        range.min = null;
      } else {
        // Update min based on previous range max
        this.changeRange(index - 1, 'max');
      }

      if (index === ranges.length - 1) {
        range.max = null;
      }
    });
  }

  private static _parseDecimal(number) {
    let decimal;
    try {
      decimal = new Decimal(number);
    } catch (err) {
      // Unparsable string. Not a number.
      return null;
    }

    if (!decimal.isPositive()) {
      // We are only interested in positive numbers
      return null;
    }

    return decimal;
  }

  /* Utils */
  toArray(obj) {
    return Object.keys(obj).map(key => obj[key]);
  }
}
