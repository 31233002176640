import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CompanyProvider } from '../../providers/company.provider';
import { TerritoryProvider } from '../../providers/territory.provider';
import { combineAll } from "rxjs/operator/combineAll";

declare var $:any;

@Component({
  selector: 'app-company-detail',
  templateUrl: './company-detail.component.html',
  styleUrls: [
    './company-detail.component.scss',
    '../client-detail/client-detail.component.scss'
  ],
  providers: [
    CompanyProvider,
    TerritoryProvider,
  ]
})
export class CompanyDetailComponent implements OnInit {
  constructor (
    private changeDetector: ChangeDetectorRef,
    private route: ActivatedRoute,
    private companyProvider: CompanyProvider,
    private territoryProvider: TerritoryProvider,
    private toastr : ToastrService
  ) {}

  public id:any;
  public company:any = {
    user: {},
    territories: [],
    billingAddress: {},
  };

  public deliveryOptions:any;
  public deliveryOptionsErrors:any = {};
  ngOnInit() {
    return this.route.params.subscribe((params:any) => {
      this.id = params.id;
      return this.getCompany();
    });
  }

  getCompany() {
    return this.companyProvider
      .getCompany(this.id)
      .then(data => {
        this.company = data;
        this.getDeliveryOptions();
      })
      .catch(err => {
        // TODO: proper error handling
        console.error(err);
      });
  }

  assignZip(zipInput) {
    if (!zipInput.value) {
      console.error('Zip code cannot be empty');
      return;
    }
    return this.companyProvider
      .bindTerritory(this.id, zipInput.value)
      .then(() => {
        zipInput.value = '';
        // TODO: Pushing the new territory into array does not display it. Workaround: load the whole company
        return this.getCompany();
/*
        this.company.territories.push(territory);
        this.company.territories = this.company.territories.slice();
        this.changeDetector.markForCheck();
*/
      })
      .catch(err => {
        // TODO: proper error handling
        console.error(err);
      })
  }

  removeZip(territory){
    if(confirm("Would you like to remove this ZIP?") == true){
      this.companyProvider.unbindTerritory(this.id, territory.zip)
      .then(response => {
        this.getCompany();
      })
      .catch(err => {
        console.log(err);
      });
    }
  }

  getDeliveryOptions(){
    this.companyProvider.getDeliveryOptions(this.company.id)
    .then((response:any) => {
      this.deliveryOptions = response;
    })
    .catch(err => {
      console.log(err);
    });
  }

  updateDeliveryOptions(){
    this.companyProvider.updateDeliveryOptions(this.company.id, this.deliveryOptions)
    .then((response:any) => {
      this.toastr.success(`Delivery options updated!`,'Great!');
    })
    .catch(err => {
      console.log(err);
    })
  }

  getStatusText(company) {
    let texts = {
      unapproved: 'Not Approved',
      approved: 'Approved',
    };
    return texts[company.status];
  }

  setBadge(event){
    this.companyProvider.updateCompany(this.company.id, {bestPriceBadge : this.company.bestPriceBadge})
    .then(response => {
      this.toastr.success(`Best Price Badge set to : ${this.company.bestPriceBadge}`,'Great!');
    });
  }
}
