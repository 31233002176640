import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CompanyProvider } from '../../providers/company.provider';
import { OrderProvider } from '../../providers/order.provider';
import { ServiceProvider } from '../../providers/service.provider';
import { StaticProvider } from '../../providers/static.provider';
import { UserProvider } from '../../providers/user.provider';
import { BehaviorSubject } from 'rxjs';

declare var $: any;

@Component({
  selector: 'app-clientdetail',
  templateUrl: './client-detail.component.html',
  styleUrls: ['./client-detail.component.scss'],
  providers: [
    CompanyProvider,
    OrderProvider,
    UserProvider,
    ServiceProvider,
    StaticProvider,
  ]
})
export class ClientDetailComponent implements OnInit {
  private customerSubject = new BehaviorSubject<any>(null);
  private companyNameSubject = new BehaviorSubject<string>(null);
  customer$ = this.customerSubject.asObservable();
  companyName$ = this.companyNameSubject.asObservable();
  public states: any[] = [];
  public confirm = false;
  public id: any;
  public customer: any = {
    billingAddress: {},
  };
  public userStatuses = ['pending', 'unverified', 'active', 'disabled'];
  public customerEdit: any = {
    billingAddress: {},
  };
  public customerOrders: Array<any>;
  public confirmModalText: string;
  public recentOrders: any;
  public orderData: any = {
    userId: null,
    tankId: null,
    priceType: 'pool',
    orderedAmount: 100,
    orderedAmountType: 'gallons'
  };
  public deliveryCompanies: any;
  public deliveryCompanyData: any = {
    id: null,
    name: null
  };

  public zipAvailableServices: any = {
    availableServices: {},
    servicePrices: {},
  };
  public enabledServicesEdit: any = {};

  public cardsLoading = true;
  public cards: any[] = [];

  public confirmTokenResetText = 'Are you sure you want to send token reset email?';

  constructor(
    private companyProvider: CompanyProvider,
    private route: ActivatedRoute,
    private orderProvider: OrderProvider,
    private userProvider: UserProvider,
    private serviceProvider: ServiceProvider,
    private staticProvider: StaticProvider,
    private toastr: ToastrService
  ) {
    this.enabledServicesEdit = this.getEnabledServicesEdit();
  }

  ngOnInit() {
    return this.route.params.subscribe((params: any) => {
      this.id = params.id;
      return Promise.all([
        this.getCustomer(),
        this.getOrders(),
        this.getStates(),
        this.getCards()
      ]);
    });
  }

  async getCustomer() {
    try {
      const customer = await this.userProvider.getById(this.id);

      this.customerSubject.next(customer);
      this.customer = customer;
      this.customer.enabledServices = this.customer.enabledServices || {};
      this.customerEdit = JSON.parse(JSON.stringify(customer));
      this.customerEdit.billingAddress = this.customerEdit.billingAddress || {};
      this.enabledServicesEdit = this.getEnabledServicesEdit();
      this.customer.notActiveReason = this.customer.notActiveReason ? this.customer.notActiveReason : '';
      const customerZip = (this.customer.status === 'pending') ? this.customer.pendingZip : this.customer.billingAddress.zip;
      const companies = await this.userProvider.getAllServingCompanies(this.customer.id, customerZip);

      this.deliveryCompanies = companies;

      this.orderData.tankId = this.customer.tank ? this.customer.tank.id : null;
      this.orderData.userId = this.customer.id;
    } catch (e) {
      // TODO: proper error handling
      console.error(e);
    }
  }

  getEnabledServicesEdit() {
    const services = this.customer && this.customer.enabledServices || {};
    const result: any = {};
    const serviceNames = ['heatingService', 'autoFill', 'tankMonitoring', 'noCostEnergyAudit'];
    const priceNames = ['monthlyServicePrice', 'monthlyAutofillPrice', 'monthlyTankMonitoringPrice', 'monthlyNoCostEnergyAuditPrice'];
    serviceNames.forEach((key, i) => {
      result[key] = {
        enabled: services[key] && services[key].enabled,
        price: services[key] && services[key].price || this.zipAvailableServices.servicePrices[priceNames[i]] || 0,
        type: services[key] && services[key].type,
      };
    });
    return result;
  }

  async getOrders() {
    try {
      const orders: any = await this.orderProvider.getAll({userId: this.id});
      this.customerOrders = orders.list;
    } catch (e) {
      // TODO: proper error handling
      console.error(e);
    }
  }

  async getCards() {
    const res: any = await this.userProvider.getUserCards(this.id);
    this.cards = res.list;
    this.cardsLoading = false;
  }

  async getStates() {
    this.states = await this.staticProvider.getStates();
  }

  updateDeliveryCompany() {
    $('#UpdateDeliveryCompany').modal('show');
  }

  confirmDelete() {
    this.confirmModalText = `You are about to delete customer ${this.customer.email}. Are you sure?`;
    $('#confirmDeleteModal').modal('show');
  }

  async deleteCustomer() {
    try {
      const user: any = await this.userProvider.setStatus(this.id, {status: 'disabled'});
      this.customer.status = user.status;
    } catch (e) {
      // TODO: proper error handling
      console.error(e);
    }
  }

  orderAmountChange() {
    if (this.orderData.orderedAmount !== 'fill') {
      this.orderData.orderedAmountType = 'gallons';
      this.orderData.priceEstimate = (this.orderData.orderedAmount * this.orderData.pricePerGallon).toFixed(2);
    } else {
      this.orderData.orderedAmountType = 'fill';
    }
  }

  deliveryCompanyChange(id) {
    this.deliveryCompanyData.id = parseInt(id, 10);
    const matchedCompany = this.deliveryCompanies.find(company => company.id === this.deliveryCompanyData.id);
    this.deliveryCompanyData.name = matchedCompany.name;
    this.companyNameSubject.next(this.deliveryCompanyData.name);
  }

  async newOrder() {
    // const deliveryCompanies = await this.userProvider.getAllServingCompanies(this.customer.id, this.getZip());
    // console.log(deliveryCompanies);
    const config: any = await this.companyProvider.getCompanyPriceEstimate(this.customer.deliveryCompanyId);
    this.orderData.pricePerGallon = config.data.pool;
    this.orderAmountChange();
    // console.log(config);
    $('#NewOrder').modal('show');
  }


  async confirmOrder() {
    $('#NewOrder').modal('hide');
    let response: any;
    try {
      response = await this
        .orderProvider
        .newOrder(this.orderData);
    } catch (err) {
      if (err.error.constraint === 'recent_orders') {
        this.recentOrders = err.error.data;
        $('#OrderOverride').modal('show');
      }
    }
    if (!response) {
      return;
    }

    this.getOrders();
    this.toastr.success('Order sent!', 'Great!');
    this.confirm = false;
    delete this.orderData.force;
  }

  overrideOrder() {
    this.orderData.force = 'recent_orders';
    this.confirmOrder();
    $('#OrderOverride').modal('hide');
  }

  getTankInspectionStatusText(tankInspection) {
    return tankInspection.status[0].toUpperCase() + tankInspection.status.substr(1);
  }

  async confirmUpdateDeliveryCompany() {
    $('#UpdateDeliveryCompany').modal('hide');

    await this.userProvider.updateDeliveryCompany(this.id, {companyId: this.deliveryCompanyData.id});
    this.getCustomer();
  }

  updateUserInfo() {
    $('#UpdateUserInfo').modal('show');
  }

  async confirmUpdateUserInfo() {
    $('#UpdateUserInfo').modal('hide');
    if (this.customerEdit.billingAddress.state.toString) {
      this.customerEdit.billingAddress.state = this.customerEdit.billingAddress.state.toString();
    }
    await this.userProvider.updateInfo(this.id, this.customerEdit);
    await this.getCustomer();
  }

  getZip() {
    return this.customer.billingAddress && this.customer.billingAddress.zip || this.customer.pendingZip;
  }

  async updateEnabledServices() {
    this.zipAvailableServices = await this.serviceProvider.getServicesForZip(this.getZip(), this.customer.pendingOrderAmount);
    $('#UpdateEnabledServices').modal('show');
  }

  formatNumber(number: number | string) {
    if (!number) {
      return '0.00';
    }
    if (typeof number === 'string') {
      return number;
    }
    return number.toFixed(2);
  }

  async confirmUpdateEnabledServices() {
    $('#UpdateEnabledServices').modal('hide');
    Object.keys(this.enabledServicesEdit).forEach(serviceName => {
      this.enabledServicesEdit[serviceName].enabled = !!this.enabledServicesEdit[serviceName].enabled;
      this.enabledServicesEdit[serviceName].price = this.formatNumber(this.enabledServicesEdit[serviceName].price);
      this.enabledServicesEdit[serviceName].type = 'monthly';
    });
    await this.userProvider.updateSubscription(this.id, {enabledServices: this.enabledServicesEdit});
    this.getCustomer();
  }

  autocompleListFormatter = (data: any) => {
    return `<span>${data.name}</span>`;
  };

  async notActiveReasonChange(isNote) {
    const response: any = await this.userProvider.updateNotActiveReason(this.id, {
      notActiveReason: this.customer.notActiveReason,
      notActiveReasonNote: this.customer.notActiveReasonNote
    });

    if (!response.err) {
      if (isNote) {
        this.toastr.success('User other reason note updated!', 'Great!');
      } else {
        this.toastr.success('User active/inactive updated!', 'Great!');
      }
    }
  }

  async sendTokenReset() {
    const response: any = await this.userProvider.requestNewPassword(this.customer.email);

    if (!response.err) {
      return this.toastr.success('Email sent to user!', 'Success!');
    }

    return this.toastr.error('There was an error sending email to user!', 'Success!');
  }
}
