import { Injectable } from '@angular/core';
import { API } from './api.provider';

@Injectable()
export class ZoneProvider {
  constructor(
    private api : API
  ) {}

  addZone(name, state){
  	return this.api.post('/zone',{name : name, state : state});
  }

  addZip(zoneId, zip){
  	return this.api.post('/territory' , {zoneId : zoneId, zip : zip});
  }

  removeZip(zipId){
    return this.api.delete('/territory/' + zipId);
  }

  getZonesByState(state){
  	return this.api.get('/zone/state/' + state);
  }

  getZoneById(id){
  	return this.api.get('/zone/' + id);
  }

  getZoneCompanies(id){
  	return this.api.get('/zone/' + id + '/companies');
  }

  getZoneOrders(id){
  	return this.api.get('/zone/' + id + '/orders');
  }

  getZoneZips(id){
  	return this.api.get('/territory/zone/' + id);
  }

  getZoneStates(){
    return this.api.get('/zone/state');
  }
}