import { Injectable } from '@angular/core';
import { API } from './api.provider';

@Injectable()
export class ServiceCompanyProvider {
  constructor(
    private api : API
  ) {}

  getAll() {
    // TODO: limit, offset and sort params
    return this.api.get('/service-company?_limit=100');
  }

  getServiceCompany(id) {
    return this.api.get(`/service-company/${id}`);
  }

  updateServiceCompany(id, body){
    return this.api.patch(`/service-company/${id}`, body);
  }

  approveServiceCompany(id) {
    return this.api.patch(`/service-company/${id}`,{status : 'approved'});
  }

  bindTerritory(id, zip) {
    return this.api.put(`/service-company/${id}/territory/${zip}`, {});
  }

  unbindTerritory(id, zip) {
    return this.api.delete(`/service-company/${id}/territory/${zip}`);
  }

  getServiceCompanyConfig(id, name){
    return this.api.get(`/service-company/${id}/config/${name}`);
  }
  setServiceCompanyConfig(id, name, value){
    return this.api.patch(`/service-company/${id}/config/${name}`, value);
  }
}
