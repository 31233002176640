import { Injectable } from '@angular/core';
import { API } from './api.provider';


@Injectable()
export class ServiceProvider {
  constructor(
    private api: API
  ) {

  }

  getServiceSubscribers() {
    return this.api.get('/service/subscribers');
  }

  async getServicesForZip(zip, amount): Promise<any> {
    const status = await this.api.get('/territory/' + zip + '/status') as any;
    // const prices = await this.api.get(`/price/customer?amount=${amount}&zip=${zip}`) as any;
    // console.log(prices);
    return {
      availableServices : status.availableServices,
      servicePrices : status.servicePrices
    };
  }
}
