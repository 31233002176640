import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PriceProvider } from '../../providers/price.provider';
import { ServiceCompanyProvider } from '../../providers/service-company.provider';

@Component({
  selector: 'app-companies',
  templateUrl: './service-companies.component.html',
  styleUrls: ['./service-companies.component.scss'],
  providers: [
    PriceProvider,
    ServiceCompanyProvider
  ],
})
export class ServiceCompaniesComponent implements OnInit {
  constructor(
    private _serviceCompanyProvider: ServiceCompanyProvider,
    private priceProvider: PriceProvider,
    public toastr : ToastrService
  ) { }

  public metadata : any;
  public companies : Array<any>;
  public prices : any;

  ngOnInit() {
    this.loadView();
  }

  loadView(){
    return Promise
      .all([
        this._serviceCompanyProvider.getAll(),
        this.priceProvider.getCustomerPrices(),
      ])
      .then((data:any) => {
        this.companies = data[0].list;
        this.metadata = data[0]._metadata;
        this.prices = data[1];
      })
      .catch(err => {
        // TODO: proper error handling
        console.error(err);
      })
  }

  approveCompany(id){
    this._serviceCompanyProvider.approveServiceCompany(id)
    .then((data:any) => {
      if(!data.err){
        this.toastr.success('Company accepted!','Success!');
      }
      this.loadView();
    });
  }
}
