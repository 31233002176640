import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-company',
  templateUrl: './dashboard-company.component.html',
  styleUrls: ['./dashboard-company.component.scss']
})
export class DashboardCompanyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
