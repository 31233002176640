import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { OrderProvider } from '../../providers/order.provider';
import { Broadcaster } from '../../broadcaster';

declare var $: any;

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
  providers: [
    OrderProvider,
  ]
})
export class OrdersComponent implements OnInit {
  public metadata: any = {total: 0};
  public orders: Array<any>;
  public pools: Array<any>;
  public pool_meta: any = {total: 0};

  public loadLimit: any = 10;
  public loadOffset: any = 0;

  public hasMoreOrders: any = true;
  public hasMorePools: any = true;

  public confirmChargeModalText = 'You are about to charge an autofill order and send it to a company. Are you sure?';
  public autofillOrderToCharge: any = true;

  public filterId;

  constructor (
    private orderProvider: OrderProvider,
    public toastr: ToastrService,
    private broadcaster: Broadcaster
  ) {}

  ngOnInit() {
    this.loadAllOrders();
  }

  loadAllOrders() {
    this.getPools();
  }

  getPools() {
    this.loader(true);
    this.pools = [];
    return this.orderProvider
    .getCompanyOrders({_limit: this.loadLimit})
    .then((data: any) => {
      if (data.list.length < this.loadLimit) {
        this.hasMorePools = false;
      }
      this.pool_meta = data._metadata;
      this.pools = data.list;
      console.log(this.pools);
      this.loader(false);
    });
  }

  loadMorePools() {
    this.loader(true);
    this.loadOffset += this.loadLimit;
    this.orderProvider
      .getCompanyOrders({_limit: this.loadLimit, _offset: this.loadOffset})
      .then((data: any) => {
        if (data.list.length < this.loadLimit) {
          this.hasMorePools = false;
        }
        this.pools = this.pools.concat(data.list);
        this.loader(false);
      });
  }

  getOrderTypeText(order) {
    const types = {
      pool: 'Pool',
      spot: 'Spot',
    };
    return types[order.priceType];
  }
  getOrderDeliveryStatusText(order) {
    const statuses = {
      processing: 'Processing',
      pending: 'Awaiting approval',
      accepted: 'Accepted',
      rejected: 'Rejected',
      delivered: 'Delivered',
      closed: 'Closed',
      open: 'Open'
    };
    return statuses[order.deliveryStatus];
  }
  getOrderDeliveryStatusClass(order) {
    return order.deliveryStatus;
  }

  tabChange(type) {
    this.loadOffset = 0;
    switch (type) {
      case 'pools':
        this.getPools();
        break;
    }
  }

  orderMetadataChange($event) {
    this.metadata = $event;
  }

  loader(state) {
    this.broadcaster.emit('app:loader', state);
  }

  confirmChargeAutofillOrder(order: any) {
    this.autofillOrderToCharge = order;
    $('#confirmChargeModal').modal('show');
  }

  setOrderDeliveryStatus(id, status) {
    this.orderProvider.setOrderDeliveryStatus(id, status)
    .then(response => {
      this.toastr.success('Order status changed to "' + status + '"!', 'Success!');
      const order = this.orders.find(o => o.id === id);
      order.deliveryStatus = status;
    })
  }
}
