import { Component, OnInit, Input } from '@angular/core';
import { ViewChild } from "@angular/core";

import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs/Subject';

import { CompanyPricingComponent } from './company-pricing/company-pricing.component';
import { PricingConfigFieldsComponent } from './config-fields/config-fields.component';
import { PricingOutputComponent } from './output/output.component';

@Component({
  selector: 'pricing-table',
  templateUrl: './pricing-table.component.html',
  styleUrls: ['./pricing-table.component.scss'],
  providers: [],
})
export class PricingTableComponent implements OnInit {
  // Component for displaying and modifying pricing config.
  // Can be used for both company and default values.

  constructor(
  ) { }

  @ViewChild(CompanyPricingComponent)
  private companyPricingComponent: CompanyPricingComponent;

  @ViewChild(PricingConfigFieldsComponent)
  private pricingConfigFieldsComponent: PricingConfigFieldsComponent;

  @ViewChild(PricingOutputComponent)
  private pricingOutputComponent: PricingOutputComponent;

  @Input() set companyId(companyId) {
    this._companyId$.next(companyId);
  }
  get companyId() {
    return this._companyId$.getValue();
  }

  // 'default' or 'company'
  @Input() type: string;

  hasChanged$ = new BehaviorSubject<boolean>(false);
  isSaveDisabled$ = new BehaviorSubject<boolean>(true);
  useDefault$ = new BehaviorSubject<boolean>(null);
  private _companyId$ = new BehaviorSubject<number>(undefined);
  private _saveTrigger$ = new Subject();

  ngOnInit() {
    this.hasChanged$
      .subscribe(hasChanged => {
        this.isSaveDisabled$.next(!hasChanged);
      });

    this._saveTrigger$
      .subscribe(() => {
        this.companyPricingComponent.save();
        this.pricingConfigFieldsComponent.save();
        this.hasChanged$.next(false);
      });
  }

  onChange() {
    this.hasChanged$.next(true);
  }

  onConfigFieldsSaved() {
    // Reload calculated values
    this.pricingOutputComponent.load();
  }

  onCompanyPricingSaved() {
    // Reload calculated values
    this.pricingOutputComponent.load();
  }

  save() {
    this._saveTrigger$.next();
  }

  setUseDefault(value) {
    if (value === this.useDefault$.getValue()) {
      return;
    }
    this.hasChanged$.next(true);
    this.useDefault$.next(value);
  }
}
