import { Component, OnInit } from '@angular/core';
import { UserProvider } from '../../providers/user.provider';
import { AuthProvider } from '../../providers/auth.provider';

import { Router } from '@angular/router';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers : [UserProvider]
})

export class LoginComponent implements OnInit {
  public form:any = {
    email : '',
    password : ''
  };
  public formErrors:any = {};

  constructor(private userProvider : UserProvider, public authProvider : AuthProvider, private router : Router) {

  }

  submit(event){
    if(event.keyCode == 13){
      this.submitForm(event);
    }
  }

  submitForm(event){
    localStorage.removeItem('JWT');
    
    this.userProvider.signIn(this.form.email, this.form.password)
    .then((response:any) => {
      if(!response.err){
        this.authProvider.setToken(response.data.token);
        this.userProvider.getUserInfo()
        .then((user:any) => {
          if(user.role == 'admin'){
            this.authProvider.setUser(user);
            this.router.navigate(['pricing']);
          } else {
            localStorage.clear();
            window.location.pathname = '/login';
          }
          
        });
      } else {
        this.formErrors = response.err.errors;
        console.log(this.formErrors);
      }
    });
  }


  ngOnInit() {
  }

}
