import { Component, OnInit } from '@angular/core';
import { StaticProvider } from '../../providers/static.provider';
import { ZoneProvider } from '../../providers/zone.provider';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
@Component({
  selector: 'app-dashboard-zones',
  templateUrl: './dashboard-zones.component.html',
  styleUrls: ['./dashboard-zones.component.scss'],
  providers : [ StaticProvider, ZoneProvider]
})
export class DashboardZonesComponent implements OnInit {
  public states:any;
  public newZoneName:String = '';
  public newZoneState:any;
  public zones:any;
  public zoneStates:any;
  public activeState:String = '';
  constructor(private staticProvider : StaticProvider, private zoneProvider : ZoneProvider, public toastr : ToastrService, private router : Router) { }

  ngOnInit() {
  	this.getStates();
    this.getZoneStates();
  }

  getStates(){
  	this.staticProvider.getStates()
  	.then(response => {
  		this.states = response;
  		this.setActiveState(this.states[0].abbreviation);
  	});
  }

  getZoneStates(){
    this.zoneProvider.getZoneStates()
    .then(response => {
      this.zoneStates = response;
    })
  }

  setActiveState(state){
  	this.activeState = state;
  	this.loadStateZones(state);
  }

  loadStateZones(state){
  	this.zoneProvider.getZonesByState(state)
  	.then((response:any) => {
  		this.zones = response.list;
  	})
  }

  addZone(){
  	if(!this.newZoneState || !this.newZoneName){
      this.toastr.error('Please fill all the fields','Whoops!');
      return;
    }
  	this.zoneProvider.addZone(this.newZoneName, this.newZoneState.abbreviation)
  	.then((response:any) => {
      this.toastr.success('New zone ' + this.newZoneName + ' added!', 'Success!');
      this.router.navigate(['/dashboard/orders/' + response.data.id]);
  	});
  }
  autocompleListFormatter = (data: any) => {
    let html = `<span>${data.name}</span>`;
    return html;
  }
}
