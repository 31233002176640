import { Component, OnInit } from '@angular/core';
import { AuthProvider } from '../../providers/auth.provider';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  providers : [ AuthProvider ]
})
export class LandingComponent implements OnInit {

  constructor(private authProvider :  AuthProvider) { }

  ngOnInit() {
  	let user = this.authProvider.getUser();
  	if(user){
  		window.location.pathname = '/pricing';
  	} else {
  		window.location.pathname = '/login';
  	}
  }

}
