import { Injectable } from '@angular/core';
import { API } from './api.provider';
@Injectable()
export class UserNoteProvider {

  constructor(private api : API){

  }

  postUserNote(companyFromId, userToId, comment) {
    return new Promise(resolve => {
      this.api.post('/user-note',{companyFromId : companyFromId, userToId : userToId, comment : comment})
      .then(response => {
        resolve(response);
      })
    })
  }
}
