import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Broadcaster } from '../../broadcaster';
import { UserProvider } from '../../providers/user.provider';

declare var $: any;

@Component({
  selector: 'app-customers-table',
  templateUrl: './customers-table.component.html',
  styleUrls: ['./customers-table.component.scss'],
  providers: [
    UserProvider,
  ]
})
export class CustomersTableComponent implements OnInit {
  @Input()
  public filter: any = {};

  @Input()
  public title = 'Customers';

  @Input()
  public filtering = true;

  @Input()
  public loadLimit = 10;

  @Output()
  public metadata = new EventEmitter<any>();

  public loadOffset = 0;

  public list: any[] = [];

  public innerMetadata: any = {total: 0};

  public hasMore: any = true;

  public filterId;
  public detailViewParams: any = {};

  constructor (
    private userProvider: UserProvider,
    public toastr: ToastrService,
    private broadcaster: Broadcaster
  ) {
    const url = new URL(window.location.href);
    if (url.pathname !== '/orders') {
      this.detailViewParams.back = url.pathname;
    }
  }

  ngOnInit() {
    this.loadData();
  }

  getFilter() {
    const filter: any = Object.assign({
      _limit: this.loadLimit,
      _offset: this.loadOffset
    }, this.filter);
    if (this.filterId) {
      filter.id = this.filterId;
    }

    if (this.filter._limit) {
      this.loadLimit = this.filter._limit;
    }

    return filter;
  }

  async loadData() {
    this.loader(true);
    this.list = [];
    this.loadOffset = 0;

    const filter = this.getFilter();
    console.log(filter);
    const data: any = await this.userProvider.getAll(filter);

    if (data.list.length < this.loadLimit) {
      this.hasMore = false;
    }
    this.innerMetadata = data._metadata;
    this.metadata.emit(this.innerMetadata);
    this.list = data.list;
    this.loader(false);
  }

  async loadMoreData() {
    this.loader(true);
    this.loadOffset += this.loadLimit;
    const filter = this.getFilter();
    const data: any = await this.userProvider.getAll(filter);
    if (data.list.length < this.loadLimit) {
      this.hasMore = false;
    }
    this.list = this.list.concat(data.list);
    this.loader(false);
  }

  loader(state) {
    this.broadcaster.emit('app:loader', state);
  }
}
