import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ServiceCompanyProvider } from '../../providers/service-company.provider';
import { TerritoryProvider } from '../../providers/territory.provider';
import { ConfigProvider } from '../../providers/config.provider';
import { combineAll } from "rxjs/operator/combineAll";

declare var $:any;

@Component({
  selector: 'app-service-company-detail',
  templateUrl: './service-company-detail.component.html',
  styleUrls: [
    './service-company-detail.component.scss',
    '../client-detail/client-detail.component.scss'
  ],
  providers: [
    ServiceCompanyProvider,
    TerritoryProvider,
    ConfigProvider
  ]
})
export class ServiceCompanyDetailComponent implements OnInit {
  constructor (
    private changeDetector: ChangeDetectorRef,
    private route: ActivatedRoute,
    private _serviceCompanyProvider: ServiceCompanyProvider,
    private territoryProvider: TerritoryProvider,
    private _configProvider: ConfigProvider,
    private toastr: ToastrService
  ) {}

  public id:any;
  public company:any = {
    user: {},
    territories: [],
    billingAddress: {},
    services: []
  };

  ngOnInit() {
    return this.route.params.subscribe((params:any) => {
      this.id = params.id;
      return this.getServiceCompany();
    });
  }

  getServiceCompanyPricings(){
    Promise.all([
      this._serviceCompanyProvider.getServiceCompanyConfig(this.company.id, 'servicePrice'),
      this._serviceCompanyProvider.getServiceCompanyConfig(this.company.id, 'monthlyServicePrice')
    ])
    .then((services) => this.company.services = services);
  }

  getServiceCompany() {
    return this._serviceCompanyProvider
      .getServiceCompany(this.id)
      .then(data => {
        this.company = data;
        let availableEnabledServices = ['heatingService',];
        availableEnabledServices.map(service => {
          if(!this.company.enabledServices[service]){
            this.company.enabledServices[service] = false;
          }
        });
        this.getServiceCompanyPricings();
      })
      .catch(err => {
        // TODO: proper error handling
        console.error(err);
        this.toastr.error('Could not get service company', 'Whoops!');
      });
  }

  assignZip(zipInput) {
    if (!zipInput.value) {
      console.error('Zip code cannot be empty');
      return;
    }
    return this._serviceCompanyProvider
      .bindTerritory(this.id, zipInput.value)
      .then(() => {
        zipInput.value = '';
        return this.getServiceCompany();
      })
      .catch(err => {
        console.error(err);
      })
  }

  removeZip(territory){
    if(confirm("Would you like to remove this ZIP?") == true){
      this._serviceCompanyProvider.unbindTerritory(this.id, territory.zip)
      .then(response => {
        this.getServiceCompany();
      })
      .catch(err => {
        console.log(err);
      });
    }
  }

  updateServices(){

    let updatePromises = [];
    updatePromises.push(this._serviceCompanyProvider.updateServiceCompany(this.company.id, {enabledServices: this.company.enabledServices}));

    this.company.services.map(service => {
      updatePromises.push(this._serviceCompanyProvider.setServiceCompanyConfig(this.company.id, service.name, {value : service.value}));
    });

    Promise.all(updatePromises)
    .then(res => {
      this.toastr.success(`Service options updated!`,'Great!');
    })
  }

  getStatusText(company) {
    let texts = {
      unapproved: 'Not Approved',
      approved: 'Approved',
    };
    return texts[company.status];
  }
}
