import { Component, OnInit } from '@angular/core';
import { TerritoryProvider } from '../../providers/territory.provider';

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-territories',
  templateUrl: './territories.component.html',
  styleUrls: ['./territories.component.scss'],
  providers: [  TerritoryProvider ]
})
export class TerritoriesComponent implements OnInit {
  public territories:any;
  public filter = {zip : ''};
  public metaFilter = {
    _limit: 50,
    _offset: 0
  };
  public hasMoreResults = false;

  constructor(private _territoryProvider:  TerritoryProvider, private _toastr : ToastrService) { }

  ngOnInit() {
    this.getTerritories();
  }

  getTerritories() {
    const filter = this.filter.zip.length ? this.filter : {};

    this._territoryProvider.getTerritories(Object.assign({filter : filter} , this.metaFilter)).then((res: any) => {
      this.territories = (this.metaFilter._offset) ? this.territories.concat(res.list) : res.list;
      this.hasMoreResults = (this.territories.length !== res._metadata.total);
    });
  }

  onFilterChange() {
    this.metaFilter._offset = 0;
    this.getTerritories();
  }

  async onTerritoryUpdate(territory) {
    const res: any = await this._territoryProvider.updateTerritory(territory.id, territory);
    this._toastr.success(`Territory with ZIP ${res.zip} updated!`, `Great!`);
  }

  loadMore() {
    this.metaFilter._offset += this.metaFilter._limit;
    this.getTerritories();
  }
}
