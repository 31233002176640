import { Component, EventEmitter, Input, Output } from '@angular/core';

declare var $:any;

@Component({
  selector: 'confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {
  @Input()
  public id:string = '';
  @Input()
  public confirmText:string = '';

  constructor() { }

  @Output()
  onSubmit = new EventEmitter();

  @Output()
  onCancel = new EventEmitter();


  submit(){
  	$(`#${this.id}`).modal('hide');
  	this.onSubmit.emit();
  }

  cancel(){
  	$(`#${this.id}`).modal('hide');
  	this.onCancel.emit();
  }

  ngOnInit() {
  }

}
