import { Component, OnInit, Input } from '@angular/core';

import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';

import { PriceProvider } from '../../../providers/price.provider';

@Component({
  selector: 'pricing-output',
  templateUrl: './output.component.html',
  styleUrls: ['./output.component.scss'],
  providers: [
    PriceProvider,
  ],
})
export class PricingOutputComponent implements OnInit {
  // Component for displaying pricing config.
  // Can be used for both company and default values.

  constructor(
    private priceProvider : PriceProvider,
  ) { }

  @Input() set companyId(companyId) {
    this._companyId$.next(companyId);
  }
  get companyId() {
    return this._companyId$.getValue();
  }

  private _companyId$ = new BehaviorSubject<number>(undefined);
  private _loadTrigger$ = new BehaviorSubject<boolean>(true);

  summary$ = new BehaviorSubject<any>(null);

  ngOnInit() {
    this._loadTrigger$
      .flatMap(() => {
        return this._loadSummary({companyId: this.companyId});
      })
      .subscribe(data => {
        this.summary$.next(data);
      });

    this._companyId$
      .skip(1)
      .subscribe(() => {
        this._loadTrigger$.next(true);
      });
  }

  public load() {
    this._loadTrigger$.next(true);
  }

  private _loadSummary({companyId}: {companyId?} = {}) {
    return Observable.fromPromise(this.priceProvider.getPriceSummary({companyId}));
  }
}
