import { Component, OnInit, Input } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

import { ConfigProvider } from '../../providers/config.provider';
import { PriceProvider } from '../../providers/price.provider';

@Component({
  selector: 'app-service-pricing-table',
  templateUrl: './service-pricing-table.component.html',
  styleUrls: ['./service-pricing-table.component.scss'],
  providers: [
    ConfigProvider,
    PriceProvider,
  ],
})
export class ServicePricingTableComponent implements OnInit {
  get serviceCompanyId() {
    return this._serviceCompanyId;
  }
  @Input() set serviceCompanyId(serviceCompanyId: number) {
    if (!serviceCompanyId) {
      return;
    }
    this._serviceCompanyId = serviceCompanyId;
    this.loadAll();
  }

  private _serviceCompanyId: number;

  @Input() public type: string;

  /** @internal */
  public defaultValues: any = {};
  /** @internal */
  public values: any = {};
  /** @internal */
  public derivedValues: any = {
    customer: {},
    delivery: {},
    revenue: {},
  };

  /** @internal */
  public fields: string[] = [
    'servicePrice', 'tankMonitoringPrice', 'autofillPrice', 'noCostEnergyAuditPrice',
    'monthlyServicePrice', 'monthlyTankMonitoringPrice', 'monthlyAutofillPrice', 'monthlyNoCostEnergyAuditPrice'];
  constructor(
    private configProvider: ConfigProvider,
    private priceProvider: PriceProvider,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.fields.forEach(field => {
      this.values[field] = {name: field};
      this.defaultValues[field] = {name: field};
    });

    if (this.type === 'default') {
      // Load values when type is default. If type is company, then loading data is postponed until serviceCompanyId is set.
      this.loadAll();
    }
  }

  /** @internal */
  public getLabel(name) {
    return this.values[name].text || this.defaultValues[name].text;
  }
  /** @internal */
  public getValue(name) {
    return this.values[name].value || this.defaultValues[name].value;
  }
  /** @internal */
  public getValueForPlaceholder(name) {
    if (!this.serviceCompanyId) {
      return;
    }
    return (this.values[name]._newValue || this.defaultValues[name].value) + (this.isDefault(name) ? ' (default)' : '');
  }
  /** @internal */
  public isDefault(name) {
    return !this.values[name] || [undefined, null, ''].indexOf(this.values[name]._newValue) !== -1;
  }

  // TODO: indicate when data is loading
  // TODO: error handling

  /** @internal */
  async save() {
    const values: any = await this.saveValues();
    for (const value of values) {
      if (!this.values[value.name]) {
        continue;
      }
      value._newValue = value.value;
      Object.assign(this.values[value.name], value);
    }
    this.toastr.success('Changes saved!', 'Success!');
  }

  /** @internal */
  public someUndefined() {
    return !this.serviceCompanyId && Object.keys(this.values).some(name => !this.values[name] || [undefined, null, ''].indexOf(this.values[name]._newValue) !== -1);
  }

  private async saveValues() {
    const values = Object
      .keys(this.values)
      .filter(name => {
        const value = this.values[name];
        const oldVal = value.value && value.value.toString();
        const newVal = value._newValue && value._newValue.toString();
        return oldVal !== newVal;
      })
      .map(name => ({name, value: this.values[name]._newValue}));

    if (!values.length) {
      return [];
    }

    const result: any = await this.configProvider.updateBulk({values, serviceCompanyId: this.serviceCompanyId});
    await this.loadDerivedValues();

    return result;
  }
  private async loadAll() {
    await this.loadValues();
    await this.loadDefaultValues();
    await this.loadDerivedValues();
  }
  private async loadDefaultValues() {
    const values: any = await this.configProvider.getAll();
    for (const value of values.list) {
      if (!this.defaultValues[value.name]) {
        continue;
      }
      Object.assign(this.defaultValues[value.name], value);
    }
  }
  private async loadValues() {
    const values: any = await this.configProvider.getAll({serviceCompanyId: this.serviceCompanyId})
    for (const value of values.list) {
      if (!this.values[value.name]) {
        continue;
      }
      value._newValue = value.value;
      Object.assign(this.values[value.name], value);
    }
  }
  private async loadDerivedValues() {
    const derivedValues = await Promise.all([
      this.priceProvider.getCustomerPrices({amount: 100, serviceCompanyId: this.serviceCompanyId}),
    ]);

    this.derivedValues.customer = derivedValues[0];
    this.derivedValues.delivery = derivedValues[1];
    this.derivedValues.revenue = derivedValues[2];
  }
}
