import { Injectable } from '@angular/core';
import { API } from './api.provider';

@Injectable()
export class DashboardProvider {
  constructor(
    private api : API
  ) {}

  getStats(){
    return this.api.get('/dashboard');
  }

  promote(payload) {
    return this.api.post('/promote/', payload);
  }
}