import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ZoneProvider } from '../../providers/zone.provider';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-dashboard-orders',
  templateUrl: './dashboard-orders.component.html',
  styleUrls: ['./dashboard-orders.component.scss'],
  providers : [ ZoneProvider ]
})
export class DashboardOrdersComponent implements OnInit {
  public id:any;
  public zone:any;
  public orders:any;
  public companies:any;
  public zips:any;
  public newZip:any;
  constructor(private route : ActivatedRoute, private zoneProvider : ZoneProvider, public toastr : ToastrService) {
  	this.route.params.subscribe(params => {
       this.id = +params['id'];
    });
  }

  ngOnInit() {
  	this.loadView();
  }

  loadView(){
  	this.getZone(this.id);
  	this.getOrders(this.id);
  	this.getCompanies(this.id);
  	this.getZips(this.id);
  }

  getZone(id){
  	this.zoneProvider.getZoneById(id)
  	.then(response => {
  		console.log(response);
  	});
  }

  getZips(id){
  	this.zoneProvider.getZoneZips(id)
  	.then((response:any) => {
  		this.zips = response.list;
  	});
  }
  removeZip(id){
    this.zoneProvider.removeZip(id)
    .then((response:any) => {
      if(!response.err){
        this.toastr.info('Zip removed!');
      }
      this.loadView();
    });
  }
  getOrders(id){
  	this.zoneProvider.getZoneOrders(id)
  	.then(response => {
  		this.orders = response;
  	})
  }

  addZip(){
  	this.zoneProvider.addZip(this.id, this.newZip)
  	.then((response:any) => {
      if(!response.err){
        this.toastr.success('New zip "'+this.newZip+'" added!', 'Success!');
      }
  		this.loadView();
  	});
  }

  getCompanies(id){
  	this.zoneProvider.getZoneCompanies(id)
  	.then(response => {
  		this.companies = response;
  	});
  }

}
