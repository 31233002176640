import { Injectable } from '@angular/core';
import { AuthProvider } from './../providers/auth.provider';
import { CanActivate } from '@angular/router';
import { environment } from '../../environments/environment';
@Injectable()

export class AdminRole implements CanActivate {

  constructor(public authProvider : AuthProvider) {
  	
  }

  canActivate() {
  	 if(this.authProvider.hasUser()){
        if(this.authProvider.getUser().role === 'admin'){
          return true;
        } else {
          window.location.pathname = '/login';
        }
     } else {
      window.location.pathname = '/login';
     }
  		
  	
    
  }
}
