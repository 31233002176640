import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { OrderProvider } from '../../providers/order.provider';
import { Broadcaster } from '../../broadcaster';

declare var $: any;

@Component({
  selector: 'app-orders-table',
  templateUrl: './orders-table.component.html',
  styleUrls: ['./orders-table.component.scss'],
  providers: [
    OrderProvider,
  ]
})
export class OrdersTableComponent implements OnInit {
  @Input()
  public filter: any = {};

  @Input()
  public title = 'Orders';

  @Input()
  public filtering = true;

  @Input()
  public loadLimit = 10;

  @Output()
  public ordersMetadata = new EventEmitter<any>();

  public loadOffset = 0;

  public orders: any[] = [];

  public metadata: any = {total: 0};
  public pools: Array<any>;
  public pool_meta: any = {total: 0};


  public hasMoreOrders: any = true;
  public hasMorePools: any = true;

  public confirmChargeModalText = 'You are about to charge an autofill order and send it to a company. Are you sure?';
  public autofillOrderToCharge: any = true;

  public modalId = 'confirmChargeModal' + Math.random().toString(32).substr(2);

  public filterId;
  public orderViewParams: any = {};

  constructor (
    private orderProvider: OrderProvider,
    public toastr: ToastrService,
    private broadcaster: Broadcaster
  ) {
    const url = new URL(window.location.href);
    if (url.pathname !== '/orders') {
      this.orderViewParams.back = url.pathname;
    }
  }

  ngOnInit() {
    this.loadOrders();
  }

  getFilter() {
    const filter: any = Object.assign({
      _limit: this.loadLimit,
      _offset: this.loadOffset
    }, this.filter);
    if (this.filterId) {
      filter.id = this.filterId;
    }

    if (this.filter._limit) {
      this.loadLimit = this.filter._limit;
    }

    return filter;
  }

  async loadOrders() {
    this.loader(true);
    this.orders = [];
    this.loadOffset = 0;

    const filter = this.getFilter();
    const data: any = await this.orderProvider.getAll(filter);

    console.log(data, this.loadLimit);
    if (data.list.length < this.loadLimit) {
      this.hasMoreOrders = false;
    }
    this.metadata = data._metadata;
    this.ordersMetadata.emit(this.metadata);
    this.orders = data.list;
    this.loader(false);
  }

  async loadMoreOrders() {
    this.loader(true);
    this.loadOffset += this.loadLimit;
    const filter = this.getFilter();
    const data: any = await this.orderProvider.getAll(filter);
    if (data.list.length < this.loadLimit) {
      this.hasMoreOrders = false;
    }
    this.orders = this.orders.concat(data.list);
    this.loader(false);
  }

  getOrderTypeText(order) {
    const types = {
      pool: 'Pool',
      spot: 'Spot',
    };
    return types[order.priceType];
  }
  getOrderDeliveryStatusText(order) {
    const statuses = {
      processing: 'Processing',
      pending: 'Awaiting approval',
      accepted: 'Accepted',
      rejected: 'Rejected',
      delivered: 'Delivered',
      closed: 'Closed',
      open: 'Open'
    };
    return statuses[order.deliveryStatus];
  }
  getOrderDeliveryStatusClass(order) {
    return order.deliveryStatus;
  }

  loader(state) {
    this.broadcaster.emit('app:loader', state);
  }

  confirmChargeAutofillOrder(order: any) {
    this.autofillOrderToCharge = order;
    $('#' + this.modalId).modal('show');
  }

  async reloadUntilChargedUpdated() {
    const that = this;
    await this.loadOrders();

    console.log('is updated?');

    if (!that.orders.find(o => o.id === that.autofillOrderToCharge.id && o.deliveryStatus === 'pending')) {
      console.log('not updated');

      this.reloadUntilChargedUpdated();
    } else {
      console.log('yes');
    }
  }

  chargeAutofillOrder() {
    this.orderProvider.chargeOrder(this.autofillOrderToCharge.id).then(() => {
      this.reloadUntilChargedUpdated();
    });
  }

  setOrderDeliveryStatus(id, status) {
    this.orderProvider.setOrderDeliveryStatus(id, status)
    .then(response => {
      this.toastr.success('Order status changed to "' + status + '"!', 'Success!');
      const order = this.orders.find(o => o.id === id);
      order.deliveryStatus = status;
    })
  }
}
