import {Injectable, Inject} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import 'rxjs/Rx';
import {AuthProvider} from './auth.provider'
import { ToastrService } from 'ngx-toastr';
import {environment} from '../../environments/environment';
import { Broadcaster } from '../broadcaster';
const API_HOST = environment.apiURL;

@Injectable()
export class API {
  private token: any;

  constructor(
    public httpClient: HttpClient,
    public authProvider: AuthProvider,
    public toastr: ToastrService,
    private broadcaster :  Broadcaster
  ) {}

  get(query, search = {}, options = {}) {
    return new Promise((resolve, reject) => {

      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.authProvider.getToken() || '',
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': '0'
      });

      this.httpClient.get<any>(API_HOST + query, {headers: headers, params: new HttpParams({fromString: API.compileSearchString(search)}), observe: 'response', responseType: 'json'})
        .map((res: any) => {
          if (res.status < 200 || res.status >= 300) {
            this.handleError(res, options);
            console.log('Problem with ' + query + ' ' + this.authProvider.getToken());

          } else {
            return res.body;
          }
        })
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            this.handleError(err, options);
            console.log('Problem with ' + query + ' ' + this.authProvider.getToken());
            reject(err);
          });
    });
  }

  post(query, body, options = {}) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.authProvider.getToken() || '',
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      'Pragma': 'no-cache',
      'Expires': '0'
    });

    return new Promise((resolve, reject) => {
      this.httpClient.post<any>(API_HOST + query, body, {headers: headers, observe: 'response', responseType: 'json'})
        .map((res: any) => {
          if (res.status < 200 || res.status >= 300) {
            this.handleError(res, options);
          } else {
            return res.body;
          }
        })
        .subscribe(
          data => {
            resolve({err: null, data: data});
          },
          err => {
            this.handleError(err, options);
            reject(err);
          }
        );
    });
  }

  delete(query, options = {}) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.authProvider.getToken() || '',
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      'Pragma': 'no-cache',
      'Expires': '0'
    });

    return new Promise((resolve, reject) => {
      this.httpClient.delete<any>(API_HOST + query, {headers: headers, observe: 'response', responseType: 'json'})
        .map((res: any) => {
          if (res.status < 200 || res.status >= 300) {
            this.handleError(res, options);
          } else {
            return res.body;
          }
        })
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            this.handleError(err, options);
            reject(err);
          });
    });
  }

  put(query, body, options = {}) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.authProvider.getToken() || '',
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      'Pragma': 'no-cache',
      'Expires': '0'
    });

    return new Promise((resolve, reject) => {
      this.httpClient.put<any>(API_HOST + query, body, {headers: headers, observe: 'response', responseType: 'json'})
        .map((res: any) => {
          if (res.status < 200 || res.status >= 300) {
            this.handleError(res, options);
          } else {
            return res.body;
          }
        })
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            this.handleError(err, options);
            reject(err);
          });
    });
  }

  patch(query, body, options = {}) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.authProvider.getToken() || '',
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      'Pragma': 'no-cache',
      'Expires': '0'
    });

    return new Promise((resolve, reject) => {
      this.httpClient.patch<any>(API_HOST + query, body, {headers: headers, observe: 'response', responseType: 'json'})
        .map((res: any) => {
          if (res.status < 200 || res.status >= 300) {
            this.handleError(res, options);
          } else {
            return res.body;
          }
        })
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            this.handleError(err, options);
            reject(err);
          });
    });
  }

  handleError(err, {disableToastr}: {disableToastr?} = {}) {
    this.broadcaster.emit('app:loader',false);

    if (!disableToastr) {
      try{
        let error = err.body || err.error || err;
        this.toastr.error(error.msg, 'Whoops!');
      } catch (err) {
        console.log(err);
      }
    }

    if(err.status === 401){
      localStorage.clear();
      window.location.pathname = '/login';
    }
  }

  static compileSearchString(search) {
    let searchString = '';

    Object.keys(search).forEach(key => {
      let value = search[key];

      if (value === undefined) {
        return;
      }

      if (Array.isArray(value)) {
        value.forEach(value => {
          searchString += `&${key}=${value}`;
        });
        return;
      }

      if (!!value && typeof value === 'object') {
        Object.keys(value).forEach(subkey => {
          searchString += `&${key}[${subkey}]=${value[subkey]}`;
        });
        return;
      }

      searchString += `&${key}=${value}`;
    });

    return searchString.substr(1);
  }
}
