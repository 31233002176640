import { Injectable } from '@angular/core';
import { API } from './api.provider';

@Injectable()
export class UserProvider {

  constructor(
    private api: API
  ) { }

  signIn(email, password) {
    return this.api.post('/auth/password', {email : email, password : password});
  }

  getUserInfo() {
    return this.api.get('/user/self');
  }

  getAll(search) {
    return this.api.get('/user', search);
  }

  getById(id) {
    return this.api.get(`/user/${id}`);
  }

  setStatus(id, body) {
    return this.api.patch(`/user/${id}/status`, body);
  }

  updateInfo(id, body) {
    return this.api.patch(`/user/${id}`, body);
  }
  updateSubscription(id, body) {
    return this.api.patch(`/user/${id}/subscription`, body);
  }

  updateDeliveryCompany(id, body) {
    return this.api.patch(`/user/${id}/delivery-company`, body);
  }

  updateNotActiveReason(id, body) {
    return this.api.patch(`/user/${id}/not-active-reason`, body);
  }

  getAllServingCompanies(id, zip) {
    return this.api.get(`/user/${id}/servicing-delivery-companies?zip=${zip}`);
  }

  getUserTank(id) {
    return this.api.get(`/user/${id}/tank`);
  }
  updateUserTank(id, data) {
    return this.api.patch(`/user/${id}/tank`, data);
  }
  getCalculatedKFactor(id) {
    return this.api.get(`/dashboard/${id}/calculated-k-factor`);
  }
  getKFactorParams(id) {
    return this.api.get(`/dashboard/${id}/k-factor-params`);
  }
  patchKFactorParams(id, kFactorParams: any) {
    return this.api.patch(`/dashboard/${id}/save-k-factor-params`, {autofillEstimateParams: kFactorParams});
  }

  getUserCards(id) {
    return this.api.get(`/card/${id}`);
  }

  requestNewPassword(email, _passwordResetUrl?) {
    return this.api.post('/auth/password-reset', { email : email, _passwordResetUrl : _passwordResetUrl});
  }

}
