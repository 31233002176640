import { Component } from '@angular/core';

@Component({
  selector: 'app-timing',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss', '../pricing/pricing.component.scss'],
  providers: []
})
export class ConfigComponent {
  constructor() { }
}
