import { Component, OnInit } from '@angular/core';
import { ServiceProvider } from '../../providers/service.provider';

@Component({
  selector: 'app-service-clients',
  templateUrl: './service-clients.component.html',
  styleUrls: ['./service-clients.component.scss'],
  providers: [ServiceProvider]
})
export class ServiceClientsComponent implements OnInit {

  public subscribers:any = [];
  constructor(
  	private _serviceProvider: ServiceProvider
  ) { }

  ngOnInit() {
    this._serviceProvider.getServiceSubscribers()
      .then(subscribers => {
        this.subscribers = subscribers;
      })
  }

}
