import { Injectable } from '@angular/core';
import { API } from './api.provider';


@Injectable()
export class OrderProvider {
  constructor(
    private api: API
  ) {

  }

  getAll(search?) {
    console.log(search);
    return this.api.get('/order', search);
  }
  getOrder(id) {
    return this.api.get(`/order/${id}`);
  }
  newOrder(body) {
    return this.api.post('/order/admin', body);
  }
  getCompanyOrders(search?) {
    return this.api.get('/order-pool', search);
  }
  getPoolOrder(id) {
  	return this.api.get(`/order-pool/${id}`);
  }
  setPaymentStatus(id, status) {
    return this.api.patch(`/order-pool/${id}`, {partnerPayoutStatus : status});
  }
  setOrderDeliveryStatus(id, status, quantity?) {
    return this.api.patch(`/order/${id}/status`, {deliveryStatus : status, deliveredAmount : quantity});
  }
  chargeOrder(id) {
    return this.api.patch(`/order/${id}/charge`, {});
  }
}
