import {
  AfterViewChecked,
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { UserProvider } from '../../providers/user.provider';


@Component({
  selector: 'app-customer-tank-status',
  templateUrl: './customer-tank-status.component.html',
  styleUrls: ['./customer-tank-status.component.scss'],
  providers: [UserProvider]
})
export class CustomerTankStatusComponent implements AfterViewChecked, OnInit {

  @ViewChild('barrelimagecontainer') barrelImageContainer;
  @ViewChild('barrelglobalcontainer') barrelGlobalContainer;
  @ViewChild('barrelbackground') barrelBackground;
  @ViewChild('barrelcontainer') barrelContainer;
  @ViewChild('barrelfilled') barrelFilled;
  @ViewChild('barrelpercentage') barrelPercentage;

  @Input()
  public userId: number;


  public highlightTankParams = false;
  public viewChecked = false;
  public kFactorObj: { minKFactor, maxKFactor, calculatedKFactor };
  public userKFactorParams;
  public catalogKFactorParams;
  public tank;
  public barrelImageSettings: any = {
    width: '100%'
  };
  public editKFactor = false;
  public resizing = false;
  public chartDrawn = false;

  constructor(
    private _userProvider: UserProvider
  ) {
    console.log(this.userId);
  }

  async ngOnInit() {
    await this.getTankData();
    await this.getKFactor();
  }

  ngAfterViewChecked(): void {
    this.viewChecked = true;
    this.checkDraw();
  }


  async getTankData() {
    this.tank = await this._userProvider.getUserTank(this.userId);
    this.calcTankFillFromEstimatedAmount();
  }

  async getKFactor() {
    const kFactorObj: any = await this._userProvider.getCalculatedKFactor(this.userId);
    this.kFactorObj = kFactorObj;
    const kFactorParams: any = await this._userProvider.getKFactorParams(this.userId);
    this.userKFactorParams = kFactorParams.user;
    this.catalogKFactorParams = kFactorParams.catalog;
  }

  async saveKFactorSettings() {
    this.calcTankFillFromLevel();
    const tankUpdate = {
      size: this.tank.size,
      estimatedOilAmount: this.tank.estimatedOilAmount
    };
    await Promise.all([
      this._userProvider.patchKFactorParams(this.userId, this.userKFactorParams),
      this._userProvider.updateUserTank(this.userId, tankUpdate)
    ]);
    await this.getKFactor();
    this.editKFactor = false;
    this.countBarrelImageSettings();
  }

  calcTankFillFromEstimatedAmount() {
    this.tank.size = this.tank.size || 250;
    if (this.tank.estimatedOilAmount === null || this.tank.estimatedOilAmount === undefined) {
      this.tank.estimatedOilAmount = this.tank.size / 2;
    }
    this.tank.estimatedOilAmount = this.tank.estimatedOilAmount <= this.tank.size ? this.tank.estimatedOilAmount : this.tank.size;
    this.tank.level = Math.round(this.tank.estimatedOilAmount / this.tank.size * 100);
  }

  calcTankFillFromLevel() {
    this.tank.size = this.tank.size || 250;
    this.tank.level = this.tank.level || 50;
    this.tank.estimatedOilAmount = Math.round(this.tank.size * this.tank.level / 100);
  }

  afterChartDraw() {
    this.chartDrawn = true;
    this.checkDraw();
  }

  checkDraw() {
    if (!this.chartDrawn || !this.viewChecked) {
      return;
    }
    this.countBarrelImageSettings();
  }

  countBarrelImageSettings() {
    const container = this.barrelImageContainer.nativeElement as HTMLElement;
    const globalContainer = this.barrelGlobalContainer.nativeElement as HTMLElement;

    const backgroundElement = this.barrelBackground.nativeElement as HTMLElement;
    const fillElement = this.barrelFilled.nativeElement as HTMLElement;
    const percentageElement = this.barrelPercentage.nativeElement as HTMLElement;
    const containerElement = this.barrelContainer.nativeElement as HTMLElement;

    const containerComputedStyle = getComputedStyle(container);

    const availableWidth = container.offsetWidth - parseFloat(containerComputedStyle.paddingRight) -
      parseFloat(containerComputedStyle.paddingLeft);
    const tankFilledRatio = this.tank.level / 100;

    this.barrelImageSettings = {
      width: availableWidth - 30
    };

    const tankHeight = parseFloat(getComputedStyle(backgroundElement).height);
    const tankFilledHeight = tankHeight * tankFilledRatio;

    fillElement.style.backgroundSize = '100%';
    fillElement.style.marginTop = `${tankHeight - tankFilledHeight}px`;
    fillElement.style.height = `${tankFilledHeight}px`;
    percentageElement.style.height = `${tankHeight}px`;
    containerElement.style.height = `${tankHeight}px`;
    percentageElement.style.lineHeight = `${tankHeight}px`;
    globalContainer.style.minHeight = `${tankHeight + 90}px`;
  }
}
