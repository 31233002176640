import { Injectable } from '@angular/core';
import { API } from './api.provider';

@Injectable()
export class TerritoryProvider {
  constructor(
    private api: API
  ) {}

  createTerritory(territory) {
    return this.api.post('/territory', territory);
  }

  deleteTerritory(id) {
    return this.api.delete(`/territory/${id}`);
  }

  getTerritories(query) {
    return this.api.get('/territory', query);
  }

  updateTerritory(id, body) {
    return this.api.patch(`/territory/${id}`, body);
  }
}
