import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DashboardProvider } from '../../providers/dashboard.provider';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [DashboardProvider]
})
export class DashboardComponent implements OnInit {
  public smsSent = false;
  public stats: any = {
    orderCounts: {
    }
  };
  public ordersPastDeliveryDate: any[] = [];

  public ordersPastDeliveryDateFilter = {
    _limit: 5,
    deliveryDate: {
      max: formatDate(Date.now() - 60 * 60 * 24 * 1000, 'yyyy-MM-dd', 'en'),
    },
    deliveryStatus: ['unassigned', 'pending', 'accepted'],
  };
  public ordersNearDeliveryDateFilter = {
    _limit: 5,
    deliveryDate: {
      min: formatDate(Date.now() - 60 * 60 * 24 * 1000, 'yyyy-MM-dd', 'en'),
      max: formatDate(Date.now() + 60 * 60 * 24 * 1000, 'yyyy-MM-dd', 'en'),
    },
  };

  public halfEmptyCustomers = {
    _limit: 5,
    role: 'customer',
    estimatedTankFill: {
      max: 50,
    },
    enabledServices: {
      autoFill: true,
    }
  };

  constructor(
    private dashboardProvider: DashboardProvider
  ) { }

  ngOnInit() {
    this.getStats();
  }

  async getStats() {
    this.stats = await this.dashboardProvider.getStats();
  }

  sendPromotionalSMS(context) {
    const payload = { message: context };
    this.dashboardProvider.promote(payload)
      .then();

    this.smsSent = true;
  }

}
