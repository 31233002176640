import { Component, HostListener, ViewContainerRef } from '@angular/core';
import { API } from './providers/api.provider';
import { AuthProvider } from './providers/auth.provider';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Broadcaster } from './broadcaster';

declare var $;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [API, AuthProvider],
})

export class AppComponent {
  title = 'Slick Admin';
  public user;
  public showLoader: any = false;
  public menuOpen = false;

  @HostListener('click', ['$event'])
  onClick(event) {
    if (!this.menuOpen) {
      return;
    }

    const isNavChild = event.path.find(el => el.nodeName === 'NAV');
    if (!isNavChild) {
      this.toggleMenu(false);
    }
  }

  constructor(
    public authProvider: AuthProvider,
    private router: Router,
    public toastr: ToastrService,
    vcr: ViewContainerRef,
    private broadcaster: Broadcaster
  ) {
    // this.toastr.setRootViewContainerRef(vcr);
    router.events
      .filter(event => event instanceof NavigationEnd)
      .subscribe((event: NavigationEnd) => {

        this.user = this.authProvider.getUser();
        window.scrollTo(0, 0);
        $('[data-toggle="tooltip"]').tooltip();
        if (this.menuOpen) {
          this.toggleMenu();
        }
      });

    this.registerBroadcaster();
  }

  registerBroadcaster() {
    this.broadcaster.on<string>('app:loader')
      .subscribe(showLoader => {
        this.showLoader = showLoader;
      });
  }

  toggleMenu(toOpen?) {
    this.menuOpen = toOpen === undefined ? !this.menuOpen : toOpen;
    $('#navbarNav').collapse(this.menuOpen ? 'show' : 'hide');
  }
}
