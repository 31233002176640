import { Injectable } from '@angular/core';
import { API } from './api.provider';
@Injectable()
export class StaticProvider {

  constructor(private api: API) {

  }

  getStates(): Promise<any[]> {
    return this.api.get('/static/state-list') as Promise<any[]>;
  }
}
