import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CompanyProvider } from '../../providers/company.provider';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss'],
  providers: [
    CompanyProvider
  ],
})
export class CompaniesComponent implements OnInit {
  constructor(
    private companyProvider: CompanyProvider,
    public toastr : ToastrService
  ) { }

  public metadata: any;
  public companies: Array<any>;

  ngOnInit() {
    this.loadView();
  }

  loadView(){
    return Promise
      .all([
        this.companyProvider.getAll()
      ])
      .then((data: any) => {
        this.companies = data[0].list;
        this.metadata = data[0]._metadata;
      })
      .catch(err => {
        // TODO: proper error handling
        console.error(err);
      })
  }

  approveCompany(id){
    this.companyProvider.approveCompany(id)
    .then((data:any) => {
      if(!data.err){
        this.toastr.success('Company accepted!','Success!');
      }
      this.loadView();
    });
  }
}
