import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { RouterModule, Routes, Router, NavigationStart } from '@angular/router';

import { Ng2AutoCompleteModule } from 'ng2-auto-complete';
import { MomentModule } from 'angular2-moment';
import { ToastrModule } from 'ngx-toastr';
import { Broadcaster } from './broadcaster';
import { CustomerTankStatusComponent } from './components/customer-tank-status/customer-tank-status.component';
import { CustomersTableComponent } from './components/customers-table/customers-table.component';
import { NeedleGaugeComponent } from './components/needle-gauge/needle-gauge.component';
import { OrdersTableComponent } from './components/orders-table/orders-table.component';

import { AdminRole } from './guards/auth.guard';
import { AuthProvider } from './providers/auth.provider';

import { AppComponent } from './app.component';

import { LoginComponent } from './pages/login/login.component';
import { OrdersComponent } from './pages/orders/orders.component';
import { OrderDetailComponent } from './pages/order-detail/order-detail.component';
import { ClientsComponent } from './pages/clients/clients.component';
import { ServiceClientsComponent } from './pages/service-clients/service-clients.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { CompaniesComponent } from './pages/companies/companies.component';
import { ClientDetailComponent } from './pages/client-detail/client-detail.component';
import { CompanyDetailComponent } from './pages/company-detail/company-detail.component';
import { ServiceCompaniesComponent } from './pages/service-companies/service-companies.component';
import { ServiceCompanyDetailComponent } from './pages/service-company-detail/service-company-detail.component';
import { TerritoriesComponent } from './pages/territories/territories.component';

import { CompanyPricingComponent } from './components/pricing-table/company-pricing/company-pricing.component';
import { PricingTableComponent } from './components/pricing-table/pricing-table.component';
import { PricingConfigFieldsComponent } from './components/pricing-table/config-fields/config-fields.component';
import { PricingOutputComponent } from './components/pricing-table/output/output.component';
import { ConfigTableComponent} from './components/config-table/config-table.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { ServicePricingTableComponent } from './components/service-pricing-table/service-pricing-table.component';

import { DashboardZonesComponent } from './pages/dashboard-zones/dashboard-zones.component';
import { DashboardOrdersComponent } from './pages/dashboard-orders/dashboard-orders.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { PagesComponent } from './pages/pages.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { DashboardCompanyComponent } from './pages/dashboard-company/dashboard-company.component';
import { DashboardCompanyOrdersComponent } from './pages/dashboard-company-orders/dashboard-company-orders.component';
import { LandingComponent } from './pages/landing/landing.component';
import { ConfigComponent } from './pages/timing/config.component';
import { AutofillComponent } from './pages/autofill/autofill.component';

import { FilterPipe } from './pipes/filter.pipe';

const appRoutes : Routes = [
  { path : '', component : LandingComponent},
  { path : 'login', component : LoginComponent },
  { path : 'orders', component : OrdersComponent},
  { path : 'order/:id', data : {type : 'spot'}, component : OrderDetailComponent},
  { path : 'order-pool/:id', data : {type : 'pool'}, component : OrderDetailComponent},
  { path : 'clients', component : ClientsComponent},
  { path : 'client/:id', component : ClientDetailComponent},
  { path : 'service-clients', component : ServiceClientsComponent},
  { path : 'pricing', component : PricingComponent},
  { path : 'companies', component : CompaniesComponent},
  { path : 'company/:id', component : CompanyDetailComponent},
  { path : 'service-companies', component : ServiceCompaniesComponent},
  { path : 'service-company/:id', component : ServiceCompanyDetailComponent},
  { path : 'dashboard', component : DashboardComponent},
  { path : 'dashboard/zones', component : DashboardZonesComponent},
  { path : 'dashboard/orders/:id', component : DashboardOrdersComponent},
  { path : 'dashboard/company', component : DashboardCompanyComponent},
  { path : 'dashboard/company/orders', component : DashboardCompanyOrdersComponent},
  { path : 'logout', component : LogoutComponent},
  { path : 'timing', component : ConfigComponent},
  { path : 'autofill', component : AutofillComponent},
  { path : 'territories', component : TerritoriesComponent},
];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    OrdersComponent,
    OrderDetailComponent,
    ClientsComponent,
    ServiceClientsComponent,
    PricingComponent,
    PricingConfigFieldsComponent,
    PricingOutputComponent,
    CompaniesComponent,
    ServiceCompaniesComponent,
    ServiceCompanyDetailComponent,
    ClientDetailComponent,
    CompanyDetailComponent,
    CompanyPricingComponent,
    PricingTableComponent,
    ServicePricingTableComponent,
    DashboardZonesComponent,
    DashboardOrdersComponent,
    DashboardComponent,
    PagesComponent,
    LogoutComponent,
    DashboardCompanyComponent,
    DashboardCompanyOrdersComponent,
    LandingComponent,
    ConfigComponent,
    AutofillComponent,
    ConfigTableComponent,
    ConfirmModalComponent,
    TerritoriesComponent,
    FilterPipe,
    OrdersTableComponent,
    CustomersTableComponent,
    CustomerTankStatusComponent,
    NeedleGaugeComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    Ng2AutoCompleteModule,
    ToastrModule.forRoot(),
    RouterModule.forRoot(appRoutes),
    MomentModule,
  ],
  providers: [
    AdminRole,
    AuthProvider,
    Broadcaster
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
