import { Injectable } from '@angular/core';
import { API } from './api.provider';

@Injectable()
export class ConfigProvider {

  constructor(private api: API) {
  }

  deleteOne(opts: any ) {
    const name = opts.name;
    const companyId = opts.companyId;
    const userId = opts.userId;
    if (userId) {
      return this.api.delete(`/customer/${userId}/config/${name}`);
    }
    return this.api.delete(`/company/${companyId}/config/${name}`);
  }

  getAll(opts: any = {}) {
    const companyId = opts.companyId;
    const userId = opts.userId;
    const path = `${userId ? '/customer/' + userId : (companyId ? '/company/' + companyId : '')}/config`;
    return this.api.get(path);
  }

  getOne(opts: any, options?) {
    const name = opts.name;
    const companyId = opts.companyId;
    const userId = opts.userId;
    return this.api.get(`${userId ? '/customer/' + userId : (companyId ? '/company/' + companyId : '')}/config/${name}`, {}, options);
  }

  update(opts: any) {
    const value = opts.value;
    const name = opts.name;
    const companyId = opts.companyId;
    const userId = opts.userId;
    return this.api.patch(`${userId ? '/customer/' + userId : (companyId ? '/company/' + companyId : '')}/config/${name}`, {value});
  }

  updateBulk(opts: any) {
    const values = opts.values;
    const companyId = opts.companyId;
    const userId = opts.userId;
    const path = `${userId ? '/customer/' + userId : (companyId ? '/company/' + companyId : '')}/config/batch`;
    return this.api.patch(path, values);
  }
}
