import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-company-orders',
  templateUrl: './dashboard-company-orders.component.html',
  styleUrls: ['./dashboard-company-orders.component.scss']
})
export class DashboardCompanyOrdersComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
