import { Injectable } from '@angular/core';
import { API } from './api.provider';

@Injectable()
export class PriceProvider {

  constructor(private api : API) {
  }

  getCustomerPrices(opts?) {
    return this.api.get('/price/customer', opts);
  }

  getPriceSummary(opts?) {
    return this.api.get('/price/summary', opts);
  }
}