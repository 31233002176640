import { Component, OnInit, Input } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

import { ConfigProvider } from '../../providers/config.provider';

@Component({
  selector: 'config-table',
  templateUrl: './config-table.component.html',
  styleUrls: ['./config-table.component.scss'],
  providers: [
    ConfigProvider,
  ],
})
export class ConfigTableComponent implements OnInit {
  constructor(
    private configProvider: ConfigProvider,
    private toastr: ToastrService,
  ) {}

  private _companyId: number;
  get companyId() {
    return this._companyId;
  }
  @Input() set companyId(companyId: number) {
    if (!companyId) {
      return;
    }
    this._companyId = companyId;
    this.loadAll();
  }

  private _userId: number;
  get userId() {
    return this._userId;
  }
  @Input() set userId(userId: number) {
    if (!userId) {
      return;
    }
    this._userId = userId;
    this.loadAll();
  };

  @Input() public columns = 3;
  colsArr() {
    let arr = [];
    let i = 0;
    while (arr.length < this.columns) {
      arr.push(i++);
    }
    return arr;
  }

  @Input() public type:string;

  /** @internal */
  public defaultValues:any = {};
  /** @internal */
  public values:any = {};

  /** @internal */
  @Input() public fields:Array<string>;

  ngOnInit() {
    this.fields.forEach(field => {
      this.values[field] = {name: field};
      this.defaultValues[field] = {name: field};
    });

    if (this.type === 'default') {
      // Load values when type is default. If type is company, then loading data is postponed until companyId is set.
      this.loadAll();
    }
  }

  /** @internal */
  public getLabel(name) {
    return this.values[name].text || this.defaultValues[name].text;
  }
  /** @internal */
  public getValue(name) {
    const defaultValObj = this.defaultValues[name];
    const valObj = this.values[name];

    if (defaultValObj.type === 'bool') {
      const [yes, no] = ['Yes', 'No'];
      if (valObj.value === false) {
        return no;
      }if (valObj.value === true) {
        return yes;
      }
      return defaultValObj.value ? yes : no;
    }

    return valObj.value || defaultValObj.value;
  }
  /** @internal */
  public getValueForPlaceholder(name) {
    if (!this.companyId && !this.userId) {
      return;
    }
    return (this.values[name]._newValue || this.defaultValues[name].value) + (this.isDefault(name) ? ' (default)' : '');
  }
  /** @internal */
  public getValueType(name) {
    return this.values[name].type || this.defaultValues[name].type;
  }
  /** @internal */
  public isDefault(name) {
    return !this.values[name] || [undefined, null, ''].indexOf(this.values[name]._newValue) !== -1;
  }

  // TODO: indicate when data is loading
  // TODO: error handling

  /** @internal */
  public save() {
    return this.saveValues()
      .then((values:any) => {
        for (let value of values) {
          if (!this.values[value.name]) {
            continue;
          }
          value._newValue = value.value;
          Object.assign(this.values[value.name], value);
        }
        this.toastr.success('Changes saved!', 'Success!');
      });
  }

  /** @internal */
  public someUndefined() {
    return !this.companyId && !this.userId && Object.keys(this.values).some(name => !this.values[name] || [undefined, null, ''].indexOf(this.values[name]._newValue) !== -1);
  }

  private saveValues() {
    return new Promise((resolve, reject) => {
      let values = Object
        .keys(this.values)
        .filter(name => {
          let value = this.values[name];
          let oldVal = value.value && value.value.toString();
          let newVal = value._newValue && value._newValue.toString();
          return oldVal !== newVal;
        })
        .map(name => ({name, value: this.values[name]._newValue}));

      if (!values.length) {
        resolve([]);
        return;
      }

      this.configProvider.updateBulk({values, companyId: this.companyId, userId: this.userId}).then(resolve).catch(reject);
    });
  }
  private loadAll() {
    return this.loadValues()
      .then(() => this.loadDefaultValues());
  }
  private loadDefaultValues() {
    return this.configProvider
      .getAll()
      .then((values:any) => {
        for (let value of values.list) {
          if (!this.defaultValues[value.name]) {
            continue;
          }
          Object.assign(this.defaultValues[value.name], value);
        }
      });
  }
  private loadValues() {
    return this.configProvider
      .getAll({companyId: this.companyId, userId: this.userId})
      .then((values:any) => {
        for (let value of values.list) {
          if (!this.values[value.name]) {
            continue;
          }

          if (value.disabled) {
            delete this.values[value.name];
            this.fields.splice(this.fields.indexOf(value.name), 1);
            continue;
          }

          value._newValue = value.value;
          Object.assign(this.values[value.name], value);
        }
      });
  }
}
