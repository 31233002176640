import { Component, OnInit } from '@angular/core';

import { UserProvider } from '../../providers/user.provider';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss'],
  providers: [
    UserProvider,
  ]
})
export class ClientsComponent implements OnInit {
  public filterNotActiveReason = '';
  public metadata: any;
  public customers = [];
  public activeCustomers = [];
  public notActiveCustomers = [];
  public churnedActiveCustomers = [];
  public churnedInactiveCustomers = [];

  constructor(
    private userProvider: UserProvider,
  ) { }


  ngOnInit() {
    return this.loadView();
  }

  async loadView() {
    const data: any = await this.userProvider.getAll({role: 'customer', data: 'light'});

    this.metadata = data._metadata;
    this.customers = data.list;
    this.activeCustomers = data.list.filter(c => !c.notActiveReason);
    this.notActiveCustomers = data.list.filter(c => c.notActiveReason);
    this.churnedActiveCustomers = data.list.filter(c => {
      return (
          c.tank.estimatedOilAmount !== null &&
          c.tank.estimatedOilAmount <= 0 &&
          !c.notActiveReason &&
          c.lastDelivery &&
          c.lastDelivery.delivery_status !== 'pending' &&
          c.lastDelivery.delivery_status !== 'accepted'
        );
    });
    this.churnedInactiveCustomers = data.list.filter(c => {
      return c.tank.estimatedOilAmount !== null && c.tank.estimatedOilAmount <= 0 && c.notActiveReason;
    });
  }

  getStatusText(user) {
    return user.status[0].toUpperCase() + user.status.substr(1);
  }
}
