import {
  AfterViewChecked,
  Component,
  EventEmitter,
  Input,
  OnDestroy, Output,
  ViewChild
} from '@angular/core';
import * as d3 from 'd3';

function percToDeg(perc) {
  return perc * 360 - 90;
}

function percToRad(perc) {
  return degToRad(percToDeg(perc / 2));
}

function degToRad(deg) {
  return deg * Math.PI / 180;
}

class Needle {
  public len;
  public radius;
  public animateEl;
  constructor(_at_len, _at_radius, animateEl) {
    this.len = _at_len;
    this.radius = _at_radius;
    this.animateEl = animateEl;
  }
  drawOn(perc) {
    this.animateEl.append('circle').attr('class', 'needle-center').attr('cx', 0).attr('cy', 0).attr('r', this.radius);
    return this.animateEl.append('path').attr('class', 'needle').attr('d', this.mkCmd(perc));
  }
  mkCmd(perc) {
    let centerX, centerY, leftX, leftY, rightX, rightY, thetaRad, topX, topY;
    thetaRad = percToRad(perc / 2);
    centerX = 0;
    centerY = 0;
    topX = centerX - this.len * Math.cos(thetaRad);
    topY = centerY - this.len * Math.sin(thetaRad);
    leftX = centerX - this.radius * Math.cos(thetaRad - Math.PI / 2);
    leftY = centerY - this.radius * Math.sin(thetaRad - Math.PI / 2);
    rightX = centerX - this.radius * Math.cos(thetaRad + Math.PI / 2);
    rightY = centerY - this.radius * Math.sin(thetaRad + Math.PI / 2);
    return 'M ' + leftX + ' ' + leftY + ' L ' + topX + ' ' + topY + ' L ' + rightX + ' ' + rightY;
  }
}

@Component({
  selector: 'app-needle-gauge',
  templateUrl: './needle-gauge.component.html',
  styleUrls: ['./needle-gauge.component.scss']
})
export class NeedleGaugeComponent implements AfterViewChecked, OnDestroy {
  @Input()
  public minValue: number;
  @Input()
  public maxValue: number;
  @Input()
  public currentValue: number;
  @Input()
  public inverted: boolean;
  @Input()
  public sectionTitles: string[];

  @Output()
  public chartDrawn = new EventEmitter<number>();

  public chartDrawCount = 0;

  @ViewChild('chart') chartElement;

  public drawn = false;

  public svg: any;

  private drawWidth = 0;
  private calcWidth = 0;

  constructor() { }

  ngAfterViewChecked(): void {
    this.calcGauge();
    this.drawGauge();
    window.addEventListener('resize', this.redraw.bind(this));
  }

  redraw() {
    this.calcGauge();
    this.drawGauge();
  }

  drawGauge() {
    if (!this.chartElement || !this.svg || this.drawWidth === this.calcWidth) {
      return;
    }
    this.drawWidth = this.calcWidth;
    this.drawn = true;
    this.chartElement.nativeElement.innerHTML = '';
    this.chartElement.nativeElement.append(this.svg.node());
    this.chartDrawn.emit(++this.chartDrawCount);
  }

  calcGauge() {
    let arc, outerArc, arcEndRad, arcStartRad;

    const chartInset = 40;

    const margin = {
      top: 30,
      right: 15,
      bottom: 30,
      left: 15
    };

    const width = this.chartElement.nativeElement.offsetWidth - margin.left - margin.right + 30;
    const height = width / 2;
    const radius = width / 2;

    const barWidth = width > 320 ? 120 : width / 2 - 50;
    this.calcWidth = width;

    const svg = d3.select('body')
      .append('svg')
      .remove()
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom);

    const chart = svg.append('g').attr('transform', 'translate(' + ((width + margin.left) / 2) + ', ' + ((height + margin.top)) + ')');

    /*first sector*/
    arcStartRad = percToRad(0);
    arcEndRad = percToRad(0.5);
    arc = d3.arc().outerRadius(radius - chartInset).innerRadius(radius - chartInset - barWidth)
      .startAngle(arcStartRad).endAngle(arcEndRad);
    chart.append('path').attr('style', 'fill: #e6f5f9').attr('d', arc);

    outerArc = d3.arc().outerRadius(radius - chartInset + 10).innerRadius(radius - chartInset)
      .startAngle(arcStartRad).endAngle(arcEndRad);

    chart.append('path').attr('id', 's1').attr('style', 'fill: #fff').attr('d', outerArc);

    chart.append('text')
      .style('font-size', '20px')
      .append('textPath')
      .attr('xlink:href', '#s1')
      .text(this.sectionTitles[0]);

    /*second sector*/
    arcStartRad = percToRad(0.5);
    arcEndRad = percToRad(0.75);
    arc = d3.arc().outerRadius(radius - chartInset).innerRadius(radius - chartInset - barWidth)
      .startAngle(arcStartRad).endAngle(arcEndRad);
    chart.append('path').attr('style', 'fill: #425b76').attr('d', arc);

    outerArc = d3.arc().outerRadius(radius - chartInset + 10).innerRadius(radius - chartInset)
      .startAngle(arcStartRad).endAngle(arcEndRad);

    chart.append('path').attr('id', 's2').attr('style', 'fill: #fff').attr('d', outerArc);

    chart.append('text')
      .style('font-size', '20px')
      .append('textPath')
      .attr('xlink:href', '#s2')
      .text(this.sectionTitles[1]);

    /*third sector*/
    arcStartRad = percToRad(0.75);
    arcEndRad = percToRad(1);
    arc = d3.arc().outerRadius(radius - chartInset).innerRadius(radius - chartInset - barWidth)
      .startAngle(arcStartRad).endAngle(arcEndRad);
    chart.append('path').attr('style', 'fill: #ee7844').attr('d', arc);

    outerArc = d3.arc().outerRadius(radius - chartInset + 10).innerRadius(radius - chartInset)
      .startAngle(arcStartRad).endAngle(arcEndRad);

    chart.append('path').attr('id', 's3').attr('style', 'fill: #fff').attr('d', outerArc);

    chart.append('text')
      .style('font-size', '20px')
      .append('textPath')
      .attr('xlink:href', '#s3')
      .text(this.sectionTitles[2]);

    const needle = new Needle(radius * (width > 320 ? 0.8 : (width > 270 ? 0.7 : 0.6)), radius / 20, chart);

    let currentValueToDraw = ((this.currentValue - this.minValue) / (this.maxValue - this.minValue));
    if (this.inverted) {
      currentValueToDraw = 1 - currentValueToDraw;
    }
    needle.drawOn(1 + currentValueToDraw * 2);

    this.svg = svg;
  }

  ngOnDestroy(): void {
    this.chartDrawn.emit(0);
    this.chartElement.nativeElement.innerHTML = '';
  }
}
