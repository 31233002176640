import { Injectable } from '@angular/core';
import { API } from './api.provider';

@Injectable()
export class CompanyProvider {
  constructor(
    private api: API
  ) {}

  getAll() {
    // TODO: limit, offset and sort params
    return this.api.get('/company?_limit=100');
  }

  getCompany(id) {
    return this.api.get(`/company/${id}`);
  }

  getCompanyPriceEstimate(id) {
    return this.api.get(`/company/${id}/price-estimate`);
  }

  updateCompany(id, body) {
    return this.api.patch(`/company/${id}`, body);
  }

  approveCompany(id) {
    return this.api.patch(`/company/${id}/status`, {status : 'approved'});
  }

  getDeliveryOptions(id) {
    return this.api.get(`/company/${id}/delivery-options`);
  }

  updateDeliveryOptions(id, body) {
    return this.api.patch(`/company/${id}/delivery-options`, body);
  }

  bindTerritory(id, zip) {
    return this.api.put(`/company/${id}/territory/${zip}`, {});
  }

  unbindTerritory(id, zip) {
    return this.api.delete(`/company/${id}/territory/${zip}`);
  }
}
